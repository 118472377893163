<template>
    <div class="col-12 text-center">
        <template v-if="cryptoDataError || notEnoughData">
            <!--Error-->
            <error></error>
            <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
            <p v-if="cryptoDataError" class="alert alert-danger mt-5 text-center">{{cryptoDataError}} {{$t('request-unsuccessful')}}</p>
        </template>
        <div v-else-if="tokenData" class="text-center">
            <!--Success-->
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 mt-3"><img class="crypto-logo" :src="getCryptoLogo(tokenData.extra.cryptoCurrencyCode)" /></div>
                    <div class="col-12 col-sm-9 mt-5">
                        <p>{{$t('depositing-address')}}</p>
                        <textarea class="fullwidth w-100 small font-weight-bold text-center" v-model="cryptoAddress" ref="cryptoAddress" readonly></textarea>
                        <p>{{$t('any-transaction')}}</p>
                    </div>
                    <div v-if="cryptoAddressBarcode" class="col-12">
                        <img :src="cryptoAddressBarcode">
                        <p>{{$t('scan-barcode')}}</p>
                    </div>
                    <div v-else-if="cryptoAddressUrl" class="col-12">
                        <button class="btn btn-success" @click="copyToClipboard()">{{$t('copy-to-clipboard')}}</button>
                    </div>
                    <div class="col-12 col-sm-9 mt-4"><p>{{$t('correct-currency-code', { cryptoCurrencyCode: tokenData.extra.cryptoCurrencyCode })}}</p></div>
                    <div class="col-12 col-sm-9 mt-4" v-if="tokenData.extra.cryptoCurrencyCode === 'ETH'"><p>{{$t('eth-minimum-deposit')}}</p></div>
                    <p v-if="isCryptoAddressCopied === true" class="alert alert-success mt-2 text-center">{{$t('copy-success-crypto')}}</p>
                    <p v-else-if="isCryptoAddressCopied === false" class="alert alert-warning mt-2 text-center">{{$t('copy-failure-crypto')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getCryptoData} from "../../../services/backend.service";
import {getCryptoLogo, isMobile} from "../../../helpers/helpers";
import store from "../../../store/shared_state";

export default {
    name: 'CoinspaidDeposit',
    data(){
        return{
            notEnoughData: null,
            cryptoDataError: null,
            cryptoAddress: null,
            cryptoAddressUrl: null,
            cryptoAddressBarcode: null,
            isCryptoAddressCopied :null,
        }
    },
    components:{
        Error: () => import('../../others/Error'),
    },
    computed:{
        tokenData() { return store.state.tokenData },
    },
    mounted(){
        const tokenData = this.tokenData;

        console.log('-----Coinspaid Data from Store-----')
        console.log(tokenData)

        if(tokenData && tokenData.customerId && tokenData.extra.cryptoCurrencyCode && tokenData.processorName && tokenData.ipAddress){
            this.getCryptoData(tokenData);
        } else {
            this.notEnoughData = true;
        }
    },
    methods:{
        getCryptoLogo(cryptoCode){ return getCryptoLogo(cryptoCode); },
        getCryptoData(cpd){
            const data = {
                customerId: cpd.customerId,
                cryptoCurrencyCode: cpd.extra.cryptoCurrencyCode,
                processorName: cpd.processorName,
                ipAddress: cpd.ipAddress,
            }

            getCryptoData(data).then(res => {

                console.log('-----getCryptoData response-----');
                console.log(res);

                if(res.data.success && res.data.data){
                    this.cryptoAddressUrl = res.data.data.address;
                    this.cryptoAddress = this.cryptoAddressUrl.substring(this.cryptoAddressUrl.indexOf(":") + 1);
                    if(isMobile() === false){
                        this.cryptoAddressBarcode = `https://api.qrserver.com/v1/create-qr-code/?data=${this.cryptoAddressUrl}&size=150x150&charset-source=UTF-8`
                    }
                } else {
                    this.cryptoDataError = res.data.message && res.data.message !== "No message available" ? res.data.message : this.$t('error-2');
                }
            }).catch( e => {
                this.cryptoDataError = this.$t('error-2');
                console.log(`%c Error in coinspaid.vue - method: getCryptoData - ${e}`, 'color:red');
            });
        },
        copyToClipboard(){
            navigator.permissions.query({name: "clipboard-write"}).then(result => {
                if (navigator.clipboard && (result.state == "granted" || result.state == "prompt")) {
                    navigator.clipboard.writeText(this.cryptoAddress).then(function() {
                        this.isCryptoAddressCopied = true;
                    }.bind(this), function() {
                        this.isCryptoAddressCopied = false;
                    }.bind(this));
                } else {
                    this.$refs.cryptoAddress.select();
                    document.execCommand('copy');
                    this.isCryptoAddressCopied = true;
                }
            });
        }
    }
}
</script>

<style scoped>
    .crypto-logo{
        max-height: 90px;
    }
</style>

